var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
      attrs: { id: "email-app" },
    },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#email-app",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isEmailSidebarActive,
            callback: function ($$v) {
              _vm.isEmailSidebarActive = $$v
            },
            expression: "isEmailSidebarActive",
          },
        },
        [
          _c("email-sidebar", {
            attrs: { emailTags: _vm.emailTags, mailFilter: _vm.mailFilter },
            on: { closeSidebar: _vm.toggleEmailSidebar },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0",
          class: { "sidebar-spacer": _vm.clickNotClose },
        },
        [
          _c(
            "div",
            { staticClass: "flex border d-theme-dark-bg items-center" },
            [
              _c("feather-icon", {
                staticClass:
                  "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                attrs: { icon: "MenuIcon" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleEmailSidebar(true)
                  },
                },
              }),
              _c("vs-input", {
                staticClass:
                  "vs-input-no-border vs-input-no-shdow-focus w-full",
                attrs: {
                  "icon-no-border": "",
                  icon: "icon-search",
                  size: "large",
                  "icon-pack": "feather",
                  placeholder: "Search Mail",
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "email__actions flex items-center flex-wrap justify-between p-4 border border-r-0 border-l-0 border-solid d-theme-border-grey-light",
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "select-all-chexkbox ml-0",
                      attrs: {
                        "icon-pack": "feather",
                        icon: _vm.selectAllIcon,
                      },
                      model: {
                        value: _vm.selectAllCheckBox,
                        callback: function ($$v) {
                          _vm.selectAllCheckBox = $$v
                        },
                        expression: "selectAllCheckBox",
                      },
                    },
                    [_vm._v("Select All")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _vm.mailFilter != "sent"
                    ? _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: {
                            "vs-custom-content": "",
                            "vs-trigger-click": "",
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "cursor-pointer",
                            attrs: {
                              icon: "FolderIcon",
                              "svg-classes": "h-6 w-6",
                            },
                          }),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _vm.mailFilter != "inbox"
                                ? _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveTo("inbox")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex items-center" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              icon: "MailIcon",
                                              svgClasses: "h-4 w-4",
                                            },
                                          }),
                                          _c("span", [_vm._v("Inbox")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.mailFilter != "draft"
                                ? _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveTo("draft")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex items-center" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              icon: "Edit2Icon",
                                              svgClasses: "h-4 w-4",
                                            },
                                          }),
                                          _c("span", [_vm._v("Draft")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.mailFilter != "spam"
                                ? _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveTo("spam")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex items-center" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              icon: "InfoIcon",
                                              svgClasses: "h-4 w-4",
                                            },
                                          }),
                                          _c("span", [_vm._v("Spam")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.mailFilter != "trash"
                                ? _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveTo("trash")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex items-center" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              icon: "TrashIcon",
                                              svgClasses: "h-4 w-4",
                                            },
                                          }),
                                          _c("span", [_vm._v("Trash")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        "vs-custom-content": "",
                        "vs-trigger-click": "",
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "cursor-pointer ml-5",
                        attrs: { icon: "TagIcon", "svg-classes": "h-6 w-6" },
                      }),
                      _c(
                        "vs-dropdown-menu",
                        _vm._l(_vm.emailTags, function (label, index) {
                          return _c(
                            "vs-dropdown-item",
                            {
                              key: index,
                              staticClass: "whitespace-no-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.updateLabels(label.value)
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "h-3 w-3 inline-block rounded-full mr-3",
                                class: "bg-" + label.color,
                              }),
                              _c("span", [_vm._v(_vm._s(label.text))]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("feather-icon", {
                    staticClass: "ml-5 cursor-pointer",
                    attrs: { icon: "MailIcon", "svg-classes": "h-6 w-6" },
                    on: { click: _vm.updateMarkUnread },
                  }),
                  _vm.mailFilter != "trash"
                    ? _c("feather-icon", {
                        staticClass: "cursor-pointer ml-5",
                        attrs: { icon: "TrashIcon", "svg-classes": "h-6 w-6" },
                        on: {
                          click: function ($event) {
                            return _vm.moveTo("trash")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              ref: "mailListPS",
              tag: "component",
              staticClass: "email-content-scroll-area",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "transition-group",
                {
                  staticClass: "email__mails",
                  attrs: { name: "list-enter-up", tag: "ul", appear: "" },
                },
                _vm._l(_vm.mails, function (mail, index) {
                  return _c(
                    "li",
                    {
                      key: String(_vm.mailFilter) + String(mail.id),
                      staticClass: "cursor-pointer email__mail-item",
                      style: { transitionDelay: index * 0.1 + "s" },
                      on: {
                        click: function ($event) {
                          return _vm.updateOpenMail(mail.id)
                        },
                      },
                    },
                    [
                      _c("mail-item", {
                        attrs: {
                          mail: mail,
                          isSelected: _vm.isMailSelected(mail.id),
                        },
                        on: {
                          addToSelected: _vm.addToSelectedMails,
                          removeSelected: _vm.removeSelectedMail,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("email-view", {
        attrs: {
          emailTags: _vm.emailTags,
          openMailId: _vm.openMailId,
          isSidebarActive: _vm.isSidebarActive,
          mailFilter: _vm.mailFilter,
        },
        on: {
          markUnread: _vm.updateSingleMarkUnread,
          removeMail: _vm.removeOpenMail,
          previousMail: _vm.previousMail,
          nextMail: _vm.nextMail,
          moveTo: _vm.moveCurrentTo,
          closeSidebar: _vm.closeMailViewSidebar,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }