var render = function render(_c, _vm) {
  return _c("vx-card", { staticClass: "px-4", class: _vm.data.staticClass }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
        },
        [
          _c("div", { staticClass: "flex items-center" }, [
            _c(
              "div",
              [
                _c("vs-avatar", {
                  staticClass:
                    "sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white",
                  attrs: { src: _vm.props.mailContent.img, size: "65px" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "flex flex-col" }, [
              _c("h5", { staticClass: "mb-1" }, [
                _vm._v(_vm._s(_vm.props.mailContent.sender_name)),
              ]),
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v(_vm._s(_vm.props.mailContent.sender)),
                  ]),
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "inline-flex cursor-pointer",
                      attrs: {
                        "vs-custom-content": "",
                        "vs-trigger-click": "",
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "w-3 h-3",
                        attrs: { icon: "ChevronDownIcon" },
                      }),
                      _c(
                        "vs-dropdown-menu",
                        { staticStyle: { "z-index": "40001" } },
                        [
                          _c("div", { staticClass: "p-2" }, [
                            _c("p", { staticClass: "text-sm mb-1" }, [
                              _vm._v("From: "),
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.props.mailContent.sender) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "text-sm mb-1" }, [
                              _vm._v("To: "),
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("csv")(_vm.props.mailContent.to)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "text-sm mb-1" }, [
                              _vm._v("Date: "),
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("time")(_vm.props.mailContent.time)
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("date")(
                                        _vm.props.mailContent.time,
                                        true
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _vm.props.mailContent.cc.length
                              ? _c("p", { staticClass: "text-sm mb-1" }, [
                                  _vm._v("cc: "),
                                  _c("span", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("csv")(
                                            _vm.props.mailContent.cc
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex flex-col justify-end" }, [
            _c("span", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm._f("time")(_vm.props.mailContent.time))),
            ]),
            _c("div", { staticClass: "flex justify-end mt-2" }, [
              _c("span", { staticClass: "text-right" }, [
                _vm._v(
                  _vm._s(_vm._f("date")(_vm.props.mailContent.time, true))
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full" }, [
        _c("div", {
          staticClass: "mail__content break-words mt-8 mb-4",
          domProps: { innerHTML: _vm._s(_vm.props.mailContent.message) },
        }),
      ]),
    ]),
    _vm.props.mailContent.attachments.length
      ? _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid mb-6 flex",
            },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "PaperclipIcon" },
              }),
              _c("span", { staticClass: "block py-4" }, [
                _vm._v("ATTACHMENTS"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(
              _vm.props.mailContent.attachments,
              function (attachment, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "mail__attachment" },
                  [
                    _c(
                      "vs-chip",
                      {
                        staticClass: "px-4 py-2 mr-3",
                        attrs: { color: "primary" },
                      },
                      [_vm._v(_vm._s(attachment))]
                    ),
                  ],
                  1
                )
              }
            ),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }