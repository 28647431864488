import { render, staticRenderFns } from "./EmailMailCard.vue?vue&type=template&id=77e8754d&functional=true&"
import script from "./EmailMailCard.vue?vue&type=script&lang=js&"
export * from "./EmailMailCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77e8754d')) {
      api.createRecord('77e8754d', component.options)
    } else {
      api.rerender('77e8754d', component.options)
    }
    module.hot.accept("./EmailMailCard.vue?vue&type=template&id=77e8754d&functional=true&", function () {
      api.rerender('77e8754d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/email/EmailMailCard.vue"
export default component.exports