var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "email__email-sidebar h-full" },
    [
      _c(
        "div",
        { staticClass: "m-6 clearfix" },
        [
          _c(
            "vs-button",
            {
              staticClass: "bg-primary-gradient w-full",
              attrs: { "icon-pack": "feather", icon: "icon-edit" },
              on: {
                click: function ($event) {
                  _vm.activePrompt = true
                },
              },
            },
            [_vm._v("Compose")]
          ),
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          staticClass: "email-compose",
          attrs: {
            title: "New Message",
            "accept-text": "Send",
            "is-valid": _vm.validateForm,
            active: _vm.activePrompt,
          },
          on: {
            cancel: _vm.clearFields,
            accept: _vm.sendMail,
            close: _vm.clearFields,
            "update:active": function ($event) {
              _vm.activePrompt = $event
            },
          },
        },
        [
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              tag: "component",
              staticClass: "scroll-area p-4",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'",
                      },
                    ],
                    staticClass: "w-full mb-6",
                    attrs: {
                      name: "mailTo",
                      "label-placeholder": "To",
                      danger: !_vm.validateForm && _vm.mailTo != "",
                      "val-icon-danger": "clear",
                      success: _vm.validateForm,
                      "val-icon-success": "done",
                      color: _vm.validateForm ? "success" : "danger",
                    },
                    model: {
                      value: _vm.mailTo,
                      callback: function ($$v) {
                        _vm.mailTo = $$v
                      },
                      expression: "mailTo",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mb-6",
                    attrs: {
                      name: "mailSubject",
                      "label-placeholder": "Subject",
                    },
                    model: {
                      value: _vm.mailSubject,
                      callback: function ($$v) {
                        _vm.mailSubject = $$v
                      },
                      expression: "mailSubject",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mb-6",
                    attrs: { name: "mailCC", "label-placeholder": "CC" },
                    model: {
                      value: _vm.mailCC,
                      callback: function ($$v) {
                        _vm.mailCC = $$v
                      },
                      expression: "mailCC",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mb-6",
                    attrs: { name: "mailBCC", "label-placeholder": "BCC" },
                    model: {
                      value: _vm.mailBCC,
                      callback: function ($$v) {
                        _vm.mailBCC = $$v
                      },
                      expression: "mailBCC",
                    },
                  }),
                  _c("quill-editor", {
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.mailMessage,
                      callback: function ($$v) {
                        _vm.mailMessage = $$v
                      },
                      expression: "mailMessage",
                    },
                  }),
                  _c("vs-upload", {
                    staticClass: "compose-mail-attachment",
                    attrs: {
                      multiple: "",
                      text: "Attachments",
                      "show-upload-button": false,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "email-filter-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _c(
            "div",
            { staticClass: "px-6 pb-2 flex flex-col" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "flex justify-between items-center cursor-pointer",
                  class: { "text-primary": _vm.mailFilter == "inbox" },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/inbox` },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center mb-2" },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "MailIcon",
                          svgClasses: [
                            {
                              "text-primary stroke-current":
                                _vm.mailFilter == "inbox",
                            },
                            "h-6 w-6",
                          ],
                        },
                      }),
                      _c("span", { staticClass: "text-lg ml-3" }, [
                        _vm._v("Inbox"),
                      ]),
                    ],
                    1
                  ),
                  _vm.emailMeta.unreadMails
                    ? [
                        _vm.emailMeta.unreadMails.folder.inbox.length > 0
                          ? _c(
                              "vs-chip",
                              {
                                staticClass: "number",
                                attrs: { color: "primary" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.emailMeta.unreadMails.folder.inbox
                                      .length
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "router-link",
                {
                  staticClass: "flex items-center mt-4 mb-2 cursor-pointer",
                  class: { "text-primary": _vm.mailFilter == "sent" },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/sent` },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "SendIcon",
                      svgClasses: [
                        {
                          "text-primary stroke-current":
                            _vm.mailFilter == "sent",
                        },
                        "h-6 w-6",
                      ],
                    },
                  }),
                  _c("span", { staticClass: "text-lg ml-3" }, [_vm._v("Sent")]),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "flex justify-between items-center mt-4 cursor-pointer",
                  class: { "text-primary": _vm.mailFilter == "draft" },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/draft` },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center mb-2" },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "Edit2Icon",
                          svgClasses: [
                            {
                              "text-primary stroke-current":
                                _vm.mailFilter == "draft",
                            },
                            "h-6 w-6",
                          ],
                        },
                      }),
                      _c("span", { staticClass: "text-lg ml-3" }, [
                        _vm._v("Draft"),
                      ]),
                    ],
                    1
                  ),
                  _vm.emailMeta.draftMails
                    ? [
                        _vm.emailMeta.draftMails.length > 0
                          ? _c(
                              "vs-chip",
                              {
                                staticClass: "number",
                                attrs: { color: "warning" },
                              },
                              [_vm._v(_vm._s(_vm.emailMeta.draftMails.length))]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "router-link",
                {
                  staticClass: "flex items-center mt-4 mb-2 cursor-pointer",
                  class: { "text-primary": _vm.mailFilter == "starred" },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/starred` },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "StarIcon",
                      svgClasses: [
                        {
                          "text-primary stroke-current":
                            _vm.mailFilter == "starred",
                        },
                        "h-6 w-6",
                      ],
                    },
                  }),
                  _c("span", { staticClass: "text-lg ml-3" }, [
                    _vm._v("Starred"),
                  ]),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "flex items-center justify-between items-center mt-4 cursor-pointer",
                  class: { "text-primary": _vm.mailFilter == "spam" },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/spam` },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center mb-2" },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "InfoIcon",
                          svgClasses: [
                            {
                              "text-primary stroke-current":
                                _vm.mailFilter == "spam",
                            },
                            "h-6 w-6",
                          ],
                        },
                      }),
                      _c("span", { staticClass: "text-lg ml-3" }, [
                        _vm._v("Spam"),
                      ]),
                    ],
                    1
                  ),
                  _vm.emailMeta.unreadMails
                    ? [
                        _vm.emailMeta.unreadMails.folder.spam.length > 0
                          ? _c(
                              "vs-chip",
                              {
                                staticClass: "number",
                                attrs: { color: "danger" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.emailMeta.unreadMails.folder.spam.length
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "router-link",
                {
                  staticClass: "flex items-center mt-4 mb-2 cursor-pointer",
                  class: { "text-primary": _vm.mailFilter == "trash" },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/trash` },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "TrashIcon",
                      svgClasses: [
                        {
                          "text-primary stroke-current":
                            _vm.mailFilter == "trash",
                        },
                        "h-6 w-6",
                      ],
                    },
                  }),
                  _c("span", { staticClass: "text-lg ml-3" }, [
                    _vm._v("Trash"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "email__labels px-6 py-4" }, [
            _c("h5", { staticClass: "mb-8" }, [_vm._v("Labels")]),
            _c(
              "div",
              { staticClass: "email__lables-list" },
              _vm._l(_vm.emailTags, function (tag, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    staticClass:
                      "email__label flex items-center mb-4 cursor-pointer",
                    attrs: { tag: "span", to: `${_vm.baseUrl}/${tag.value}` },
                  },
                  [
                    _c("div", {
                      staticClass: "ml-1 h-3 w-3 rounded-full mr-4",
                      class: "border-2 border-solid border-" + tag.color,
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "text-lg",
                        class: { "text-primary": _vm.mailFilter == tag.value },
                      },
                      [_vm._v(_vm._s(tag.text))]
                    ),
                  ]
                )
              }),
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }