var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#email-app",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _vm.currentMail
            ? _c(
                "div",
                { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                    },
                    [
                      _c("div", { staticClass: "flex mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer mr-4",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ArrowRightIcon"
                                  : "ArrowLeftIcon",
                                "svg-classes": "w-6 h-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeSidebar")
                                },
                              },
                            }),
                            _vm.currentMail.subject
                              ? _c("h4", [
                                  _vm._v(_vm._s(_vm.currentMail.subject)),
                                ])
                              : _c("h4", [_vm._v("(no subject)")]),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "email__actions--single flex items-baseline",
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                icon: "StarIcon",
                                svgClasses: [
                                  {
                                    "text-warning stroke-current stroke-current":
                                      _vm.currentMail.isStarred,
                                  },
                                  "h-6 w-6",
                                ],
                              },
                              on: { click: _vm.toggleIsStarred },
                            }),
                            _vm.mailFilter != "sent"
                              ? _c(
                                  "vs-dropdown",
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      "vs-custom-content": "",
                                      "vs-trigger-click": "",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "ml-4",
                                      attrs: {
                                        icon: "FolderIcon",
                                        "svg-classes": "h-6 w-6",
                                      },
                                    }),
                                    _c(
                                      "vs-dropdown-menu",
                                      [
                                        _vm.currentMail.mailFolder != "inbox"
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveTo("inbox")
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center",
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        icon: "MailIcon",
                                                        svgClasses: "h-4 w-4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Inbox"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.currentMail.mailFolder != "draft"
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveTo("draft")
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center",
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        icon: "Edit2Icon",
                                                        svgClasses: "h-4 w-4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Draft"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.currentMail.mailFolder != "spam"
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveTo("spam")
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center",
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        icon: "InfoIcon",
                                                        svgClasses: "h-4 w-4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Spam"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.currentMail.mailFolder != "trash"
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveTo("trash")
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center",
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        icon: "TrashIcon",
                                                        svgClasses: "h-4 w-4",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Trash"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "vs-dropdown",
                              {
                                staticClass: "cursor-pointer",
                                attrs: {
                                  "vs-custom-content": "",
                                  "vs-trigger-click": "",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer ml-4",
                                  attrs: {
                                    icon: "TagIcon",
                                    "svg-classes": "h-6 w-6",
                                  },
                                }),
                                _c(
                                  "vs-dropdown-menu",
                                  { staticStyle: { "z-index": "40001" } },
                                  _vm._l(
                                    _vm.emailTags,
                                    function (label, index) {
                                      return _c(
                                        "vs-dropdown-item",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-checkbox",
                                            {
                                              attrs: {
                                                "vs-value": label.value,
                                              },
                                              model: {
                                                value: _vm.currentMailLabels,
                                                callback: function ($$v) {
                                                  _vm.currentMailLabels = $$v
                                                },
                                                expression: "currentMailLabels",
                                              },
                                            },
                                            [_vm._v(_vm._s(label.text))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("feather-icon", {
                              staticClass: "cursor-pointer ml-4",
                              attrs: {
                                icon: "MailIcon",
                                "svg-classes": "h-6 w-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("markUnread")
                                },
                              },
                            }),
                            _vm.currentMail.mailFolder != "trash"
                              ? _c("feather-icon", {
                                  staticClass: "cursor-pointer ml-4",
                                  attrs: {
                                    icon: "TrashIcon",
                                    "svg-classes": "h-6 w-6",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("removeMail")
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer ml-4 hidden sm:inline-flex",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ChevronsRightIcon"
                                  : "ChevronsLeftIcon",
                                "svg-classes": "h-6 w-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("previousMail")
                                },
                              },
                            }),
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer ml-4 hidden sm:inline-flex",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ChevronsLeftIcon"
                                  : "ChevronsRightIcon",
                                "svg-classes": "h-6 w-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("nextMail")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    _vm.scrollbarTag,
                    {
                      key: _vm.$vs.rtl,
                      tag: "component",
                      staticClass:
                        "scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6",
                      attrs: { settings: _vm.settings },
                    },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-full" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "email__labels--single flex ml-10 items-center mt-2",
                            },
                            [
                              _c(
                                "transition-group",
                                {
                                  staticClass: "flex",
                                  attrs: { name: "list", tag: "div" },
                                },
                                _vm._l(
                                  _vm.currentMail.labels,
                                  function (label) {
                                    return _c(
                                      "div",
                                      {
                                        key: label,
                                        staticClass:
                                          "open-mail-label flex items-center mr-4",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "h-3 w-3 rounded-full mr-1",
                                          class: "bg-" + _vm.labelColor(label),
                                        }),
                                        _c("span", { staticClass: "text-sm" }, [
                                          _vm._v(
                                            _vm._s(_vm._f("capitalize")(label))
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("br"),
                      _vm.currentMail.replies.length && !_vm.showThread
                        ? _c("div", { staticClass: "vx-row mb-4" }, [
                            _c("div", { staticClass: "vx-col w-full" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-primary font-medium ml-10 cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      _vm.showThread = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentMail.replies.length) +
                                      " Earlier Messages"
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.isSidebarActive
                        ? _c(
                            "div",
                            [
                              _vm._l(
                                _vm.currentMail.replies.slice().reverse(),
                                function (threadMail) {
                                  return _vm.showThread
                                    ? _c(
                                        "div",
                                        {
                                          key: threadMail.id,
                                          staticClass: "vx-row",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "vx-col w-full" },
                                            [
                                              _c("email-mail-card", {
                                                staticClass: "mb-10",
                                                attrs: {
                                                  mailContent: threadMail,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }
                              ),
                              _c("div", { staticClass: "vx-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col w-full" },
                                  [
                                    _c("email-mail-card", {
                                      attrs: { mailContent: _vm.currentMail },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "vx-row",
                                  staticStyle: { "margin-top": "2.2rem" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vx-col w-full" },
                                    [
                                      _c("vx-card", [
                                        _c("div", { staticClass: "vx-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vx-col w-full flex justify-between",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text-lg" },
                                                [
                                                  _vm._v("Click here to "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary font-semibold cursor-pointer",
                                                    },
                                                    [_vm._v("Reply")]
                                                  ),
                                                  _vm._v(" or "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary font-semibold cursor-pointer",
                                                    },
                                                    [_vm._v("Forward")]
                                                  ),
                                                ]
                                              ),
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "PaperclipIcon",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }